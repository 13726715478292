/*
 * @Date: 2024-01-26 20:55:53
 * @LastEditors: wangkai wangkai04@rd.netease.com
 * @LastEditTime: 2024-02-05 14:37:51
 * @Description: 消息提醒的小铃铛组件
 */

import { BellIcon } from '@/constants';
import styles from './Bell.module.scss';
import { useEffect } from 'react';
import { getUnreadNum } from '@/services/notification';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { useDispatch } from 'react-redux';
import { changeNotificationModalVisible, changeUnreadCount } from '@/redux/slice/common';
import { useRouter } from 'next/router';
const Bell: React.FC = () => {
  const router = useRouter();
  const { unreadCount } = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();
  const getUnread = async () => {
    const res = await getUnreadNum(); //未读消息条数
    dispatch(changeUnreadCount(res));
    // 每次重新登录系统时，若有未读信息则显示弹窗
    if (firstLogin === 'true' && res > 0) {
      dispatch(changeNotificationModalVisible(true));
    }
  };

  const { firstLogin } = router.query;
  useEffect(() => {
    getUnread();
  }, [firstLogin]);

  const handleBellClick = () => {
    if (firstLogin) router.replace(router.pathname);
    dispatch(changeNotificationModalVisible(true));
  };

  return (
    <div className={styles.notificationBell} onClick={handleBellClick}>
      <BellIcon />

      {unreadCount > 0 &&
        (unreadCount < 100 ? (
          <div className={styles.notificationCount}>{unreadCount}</div>
        ) : (
          <div className={styles.notificationCount} style={{ fontSize: '10px' }}>
            99+
          </div>
        ))}
    </div>
  );
};

export default Bell;
