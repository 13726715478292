import '../styles/globals.scss';
import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import store, { persistor } from '@/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import Layout from '@/layout/Layout';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { setAuth } from '@/utils/request';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Intl from '@/components/intl/Intl';
import { SentryLogger } from '@/utils/sentry';
import { UserRole } from '@/constants';
import Head from 'next/head';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Shanghai');

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    const email = Cookies.get('USER_EMAIL');
    const userId = Cookies.get('USER_ID');
    const userType = Cookies.get('USER_TYPE');
    const userStatus = Cookies.get('USER_STATUS');
    const authToken = Cookies.get('AUTH_TOKEN');
    const isLoggedIn = email && userId && userType && userStatus && authToken;
    isLoggedIn && setAuth(Number(userId), authToken);
    SentryLogger.setUser({
      email: email,
      id: userId,
      role: UserRole[Number(userType)],
    });
  }, []);

  return (
    <>
      <Head>
        <title>youdaoads</title>
        <meta name="description" content="Youdao Ads" />
        <meta name="keywords" content="Youdao Ads" />
        <meta name="author" content="Youdao Ads" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />
      </Head>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Intl>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </Intl>
        </PersistGate>
      </Provider>
    </>
  );
}
