const interfaceUrl = {
  /**
   * ---------------------------------------------------------
   * KOC mvp
   * 注册登录等
   * ---------------------------------------------------------
   */

  /**
   * 登录授权
   * @method post
   */
  login: '/users/authorization',

  /**
   * 第三方授权登录
   * @method GET
   */
  thirdPartyLogin: '/users/third-party/authorization',

  /**
   * MCN注册
   * @method POST
   */
  registerMcn: '/users/mcn-register',
  /**
   * 用户注册并发送激活邮件
   * @method POST
   * @return error_code 405002 邮件发送过于频繁
   */
  kolRegister: '/users/kol-register',

  /**
   * 广告主注册
   * @method POST
   */
  registerAdvertiser: '/users/advertiser-register',

  /**
   * 第三方授权KOL注册
   * @method POST
   */
  kolThirdPartyRegister: '/users/third-party/kol-register',

  /**
   * 第三方授权广告主注册
   */
  advertiserThirdPartyRegister: '/users/third-party/advertiser-register',

  /**
   * 邮箱是否已注册 2021.11.22改
   * @method GET
   */
  checkEmailRegistered: '/users/is-email-registered',

  /**
   * 获取已授权过审的社媒平台
   * @method GET
   */
  getAuthorizedPlatforms: '/oauth/get-authorized-platforms',

  /**
   * MCN角色发送激活邮件
   * @method GET
   */
  sendMCNActivateMail: '/users/mcn-active-mail',

  /**
   * 给广告主发送激活邮件
   * @method GET
   */
  sendAdvertiserActiveEmail: '/users/advertiser-active-mail',

  /**
   * 发送激活邮件
   * @method GET
   */
  sendActivateMail: '/users/kol-active-mail',
  /**
   * 激活kol账户
   * @method GET
   */
  activateKol: '/users/kol-activation',
  /**
   * 激活广告主
   * @method GET
   */
  activeAdvertiser: '/users/advertiser-activation',

  /**
   * 完善广告主信息
   * @method POST
   */
  fillAdvertiserInfo: '/users/advertiser-fill-info',

  /**
   * 校验密码重置码的有效性
   * @method GET
   */
  validatePasswordResetCode: '/users/kol-valid-reset-code',

  /**
   * 修改密码
   * @method POST
   */
  changePassword: '/users/kol-password',

  /**
   * 发送重置密码邮件
   * @method GET
   */
  sendResetMail: '/users/reset-mail',

  /**
   * 跳转到第三方登录页面——用于登录后绑定
   * @method GET
   */
  thirdPartyAuthRedirect: '/users/third-party-auth',

  /**
   * 跳转到第三方登录页面——用于登录或注册
   * @method GET
   */
  thirdPartyLoginRedirect: '/users/third-party-login',

  /**
   * ---------------------------------------------------------
   * 推广系列
   * ---------------------------------------------------------
   */
  /**
   *获取推广系列列表
   * @method POST
   */
  getPromotionList: '/advertiser/promotion/list',

  /**
   *终止推广系列
   * @method PUT
   */
  terminationPromotion: '/advertiser/promotion/termination',
  /**
   * 下拉搜索推广系列名称
   * @method GET
   */
  getPromotionDropDownList: '/drop-down/promotion/list',
  /**
   * 下拉搜索产品名称
   * @method GET
   */
  getProductDropDownList: '/drop-down/promotion/product/list',

  /**
   * 获取标签树
   * @method GET
   */
  tags: '/stored-kol/tags',
  /**
   * 上传附件
   * @method POST
   */
  uploadPromotionAttachment: '/media/promotion/attachment/upload',

  /**
   * 下载附件
   * @method GET
   */
  downloadPromotionAttachment: '/media/promotion/attachment/download',
  /**
   * 下载附件
   * @method GET
   */
  notLoginDownloadPromotionAttachment: '/media/attachment/download/share',
  /**
   * 获取推广系列详情
   * @method GET
   */
  getPromotionDetail: '/advertiser/promotion/detail',
  /**
   * 新建推广系列
   * @method POST
   */
  createPromotion: '/advertiser/promotion/add',

  /**
   * 更新推广系列
   * @method POST
   */
  updatePromotion: '/advertiser/promotion/update',

  /**
   * 导出效果追踪数据
   * @method GET
   */
  exportPromotionAttribution: '/promotion/attribution/export',
  /**
   * ---------------------------------------------------------
   * 合作达人系列
   * ---------------------------------------------------------
   */
  /**
   * kol名称和主页链接搜索建议
   * @method GET
   */
  getKolSearchAdvice: '/drop-down/promotion/kol/list',
  /**
   * 获取已接单确认达人列表
   * @method GET
   */
  getPendingConfirmPartnerList: '/advertiser/promotion/pending-confirm/list',
  /**
   * 获取进行中达人列表
   * @method GET
   */
  getConfirmPartnerList: '/advertiser/promotion/confirm/list',
  /**
   * 获取已发布达人列表
   * @method GET
   */
  getOnlinePartnerList: '/advertiser/promotion/online/list',
  /**
   * 广告主查看留言板
   * @method GET
   */
  getAdvertiserMessageList: '/advertiser/promotion/message/list',
  /**
   * 广告主账户发送留言信息
   * @method POST
   */
  sendAdvertiserMessage: '/advertiser/promotion/message/send',

  /**
   * 查询 koc 最近发布的视频
   * @method POST
   */
  getKolMediaVideoListBatch: '/kol-media/video-list/batch',

  /**
   * advertiser接受合作
   * @method PUT
   */
  rejectAdvertiserPromotion: '/advertiser/promotion/reject',
  /**
   * advertiser接受合作
   * @method PUT
   */
  confirmAdvertiserPromotion: '/advertiser/promotion/confirm',

  /**
   * 广告主账户确认合作信息（初稿、终稿等）
   * @method PUT
   */
  confirmStageAdvertiserPromotion: '/advertiser/promotion/confirm-stage',

  /**
   * ---------------------------------------------------------
   * 合作达人详情
   * ---------------------------------------------------------
   */
  /**
   * 查询kol详情
   * @method POST
   */
  getAdvertiserKolDetail: '/advertiser/kol/detail',

  /**
   * 查询kol的媒体统计数据
   * @method POST
   */
  getKolStatistic: '/kol-media/statistic',

  /**
   * 查询 kol 媒体统计数据
   * @method POST
   */
  getKolMediaStatisticList: '/kol-media/statistic-list',

  /**
   * 查询 kol 下合作视频相关统计数据
   * @method POST
   */
  getKolMediaBrandAnalysis: '/kol-media/brand-analysis',
  /**
   * 查询 kol 下合作品牌列表
   * @method POST
   */
  getKolBrandList: '/kol-media/brand-list',

  /**
   * 分页查询 kol 下视频
   * @method POST
   */
  getKolMediaVideoList: '/kol-media/video-list',

  /**
   * 查询当前 kol 的相似频道
   * @method POST
   */
  getKolSimilarKolList: '/stored-kol/similar-kol-list',
  /**
   * ---------------------------------------------------------
   * 账号管理
   * ---------------------------------------------------------
   */
  /**
   * 广告主个人信息修改
   * @method PUT
   */
  saveAdvertiserPersonalInfo: '/advertiser/personal-info',

  /**
   * 广告主个人信息
   * @method get
   */
  getAdvertiserDetail: '/advertiser/detail',

  /**
   * 广告主公司信息修改
   * @method PUT
   */
  saveAdvertiserCompanyInfo: '/advertiser/company-info',

  /**
   * 下载广告主产品附件
   * @method GET
   */
  downloadAdvertiserProductAttachment: '/media/advertiser/product/attachment/download',

  /**
   * 上传广告主产品附件
   * @method POST
   */
  uploadAdvertiserProductAttachment: '/media/advertiser/product/attachment/upload',

  /**
   * ---------------------------------------------------------
   * koc账号管理
   * ---------------------------------------------------------
   */
  /**
   * 获取KOL社交媒体账号列表
   * @method GET
   */
  getKolSocialMediaList: '/users/kol-social-media/list',

  /**
   * 绑定社媒账号
   * @method POST
   */
  bindSocialMedia: '/users/bind-social-media',

  /**
   * 主页链接校验
   * @method GET
   */
  validateHomepage: '/users/kol-valid-homepage',

  /**
   * 主页链接校验无需登录
   * @method GET
   */
  checkHomepage: '/stored-kol/check-homepage',
  /**
   * 校验主页链接是否存在
   * @method GET
   */
  checkKolUrlExsit: '/stored-kol/check-url-exist',

  /**
   * 通过验证码绑定主页链接
   * @method POST
   */
  bindingUrlByCode: '/users/kol-social-media/binding-by-code',

  /**
   * ---------------------------------------------------------
   * koc提单邀请
   * ---------------------------------------------------------
   */
  /**
   * 获取koc提单邀请列表
   * @method GET
   */
  getKocPendingConfirmList: '/koc/promotion/pending-confirm/list',
  /**
   * KOC接受邀请
   * @method PUT
   */
  confirmKocPromotion: '/koc/promotion/confirm',

  /**
   * KOC拒绝邀请
   * @method PUT
   */
  rejectKocPromotion: '/koc/promotion/reject',
  /**
   * koc获取推广系列详情
   * @method GET
   */
  getKocPromotionDetail: '/koc/promotion/detail',
  /**
   * ---------------------------------------------------------
   * koc任务
   * ---------------------------------------------------------
   */
  /**
   * 获取KOC进行中任务列表
   * @method GET
   */
  getKocPendingTaskList: '/koc/promotion/confirm/list',

  /**
   * 获取KOC已发布任务列表
   * @method GET
   */
  getKocDoneTaskList: '/koc/promotion/online/list',

  /**
   * KOC账户查看留言板
   * @method GET
   */
  getKocMessageList: '/koc/promotion/message/list',
  /**
   * koc账户发送留言信息
   * @method POST
   */
  sendKocMessage: '/koc/promotion/message/send',
  /**
   * koc上传脚本
   * @method PUT
   */
  uploadKocFile: '/koc/promotion/confirm-stage',
  /**
   * ---------------------------------------------------------
   * 消息通知
   * ---------------------------------------------------------
   */
  /**
   * 获取消息通知列表
   * @method GET
   */
  getNotificationList: '/notification/promotion/list',
  /**
   * 获取未读消息数量
   * @method GET
   */
  getUnreadNum: '/notification/promotion/unread',
  /**
   * 设置消息已读一条
   * @method PUT
   */
  uploadReadOne: '/notification/promotion/read-one',
  /**
   * 设置消息全部已读
   * @method PUT
   */
  uploadReadAll: '/notification/promotion/read-all',

  /**
   * ---------------------------------------------------------
   * 邀请kol
   * ---------------------------------------------------------
   */
  /**
   * 查询协议详情信息
   * @method GET
   */
  getCooperationAgreement: '/promotion/kol/cooperation-agreement/detail',
  /**
   * 检查KOC是否已绑定账号
   * @method GET
   */
  checkKocBound: '/koc/check-bound',
  /**
   * KOC确认协议
   * @method PUT
   */
  confirmCooperationAgreement: '/promotion/kol/cooperation-agreement/confirm',
  /**
   * ---------------------------------------------------------
   * koc个人信息
   * ---------------------------------------------------------
   */
  /**
   * 新增或修改KOC个人中心信息
   * @method PUT
   */
  updateKocInfo: '/koc/info/update',
  /**
   * 获取KOC个人中心信息详情
   * @method GET
   */
  getKocInfo: '/koc/info/detail',

  /**
   * koc钱包
   * ---------------------------------------------------------
   */
  /**
   * KOC 获取 balance 和 totalAmount
   * @method GET
   */
  getAmount: '/finance/koc/wallet/amount',
  /**
   * 查询钱包列表可筛选的最早日期
   * @method GET
   */
  getEarliestDate: '/finance/koc/wallet/date',
  /**
   * 查询 received 列表
   * @method GET
   */
  getReceivedList: '/finance/koc/wallet/received/list',
  /**
   * 查询 received 详情
   * @method GET
   */
  getReceivedDetail: '/finance/koc/wallet/received/detail',
  /**
   * 查询 unpaid 列表
   * @method GET
   */
  getUnpaidList: '/finance/koc/wallet/unpaid/list',
  /**
   * 查询 unpaid 详情
   * @method GET
   */
  getUnpaidDetail: '/finance/koc/wallet/unpaid/detail',
  /**
   * 查询 withdraw 列表
   * @method GET
   */
  getWithdrawList: '/finance/koc/wallet/withdraw/list',
  /**
   * 查询 KOL 账户绑定的 Airwallex 账户列表
   * @method GET
   */
  getBankAccountList: '/finance/kol/bank/list',
  /**
   * 查询 Airwallex 账户详情
   * @method GET
   */
  getBankAccountDetail: '/finance/kol/bank/detail',
  /**
   * 获取新增 Airwallex 账户需要填写的页面参数
   * @method POST
   */
  getBankAccountParams: '/finance/kol/bank/form',
  /**
   * 新增 Airwallex 账户
   * @method POST
   */
  addBankAccount: '/finance/kol/bank/insert',
  /**
   * 更新 Airwallex 账户
   * @method PUT
   */
  updateBankAccount: '/finance/kol/bank/update',
  /**
   * 删除 Airwallex 账户
   * @method DELETE
   */
  deleteBankAccount: '/finance/kol/bank/delete',
  /**
   * 查询 KOL 账户绑定的 PayPal 账户列表
   * @method GET
   */
  getPaypalAccountList: '/finance/kol/paypal/list',
  /**
   * 查询 PayPal 账户详情
   * @method GET
   */
  getPaypalAccountDetail: '/finance/kol/paypal/detail',
  /**
   * 新增 PayPal 账户
   * @method POST
   */
  addPaypalAccount: '/finance/kol/paypal/insert',
  /**
   * 更新 PayPal 账户
   * @method PUT
   */
  updatePaypalAccount: '/finance/kol/paypal/update',
  /**
   * 删除 PayPal 账户
   * @method DELETE
   */
  deletePaypalAccount: '/finance/kol/paypal/delete',
  /**
   * 生成提现序列号
   * @method GET
   */
  generateWithdrawSN: 'finance/generate/sn',
  /**
   * 发起 Airwallex 提现
   * @method POST
   */
  withdraw: '/finance/kol/withdraw/insert',
  /**
   * 查询可以发起转账的国家信息
   * @method GET
   */
  getWithdrawCountryInfo: '/finance/kol/withdraw/country',
  /**
   * 查询当前用户,当前币种, 当日已经提现金额
   * @method GET
   */
  getCurWithdrawAmount: '/finance/kol/withdraw/withdrawn-amount',
  /**
   * 提现金额转化接口
   * @method GET
   */
  getAirwallexWithdrawConvertToDollar: '/finance/withdraw/airwallex/convert-to-dollar',
  /**
   * 通过币种查询支持的本地银行
   * @method GET
   */
  getLocalBankByCurrency: '/finance/kol/withdraw/local/bank/list',
  /**
   * koc任务广场
   * ---------------------------------------------------------
   */
  /**
   * 获取任务广场列表
   * @method POST
   */
  getPromotionTaskList: '/koc/promotion-task/list',

  /**
   * 获取任务广场列表详情
   * @method POST
   */
  getPromotionTaskDetail: '/koc/promotion-task/detail',
  // /koc/promotion-task/detail/share

  /**
   * 获取分享列表详情
   * @method POST
   */
  getPromotionShareTaskDetail: '/koc/promotion-task/detail/share',

  /**
   * 获取koc当前平台下绑定的社媒列表
   * @method GET
   */
  getKolMediaList: '/users/kol-social-media/list',
  /**
   * koc报名任务
   * @method POST
   */
  applyPromotionTask: '/koc/promotion-task/apply',
  /**
   * 获取任务广场H5列表
   * @method POST
   */
  getH5PromotionTaskList: '/promotion-task/h5/group/detail',
  /**
   *h5 koc报名任务
   * @method POST
   */
  applyH5PromotionTask: '/koc/promotion-task/h5/apply',
};
export default interfaceUrl;
